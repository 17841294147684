// functions and script here
class NavButton {

  constructor() {
    const mediaQueryList = window.matchMedia('(min-width: 1024px)');
    document.getElementsByClassName('mobile-menu-toggler')[0].addEventListener('click', this.toggleNavigation.bind(this));
    mediaQueryList.addListener(this.screenTest.bind(this));
    for (const dropdown of document.getElementsByClassName('navigation-menu')) {
      dropdown.addEventListener('toggle', this.closeDropdowns.bind(this) );
    }
  }

  toggleNavigation(e) {
    e.currentTarget.classList.toggle('change');
    document.getElementsByTagName('body')[0].classList.toggle('mobile-menu');
    e.currentTarget.getAttribute('aria-expanded') === 'false' ? e.currentTarget.setAttribute("aria-expanded", true) : e.currentTarget.setAttribute("aria-expanded", false);
    document.getElementsByClassName('navigation-container')[0].toggleAttribute('open');
  }

  /* Toggle between showing and hiding the navigation menu links when the user clicks on the hamburger menu / bar icon */
  
  screenTest(e) {
    if (e.matches) {
      console.log('match');
      this.pruneDropdowns();
    } else {
      /*
      else {
        document.getElementsByClassName('navigation-container')[0].style.display = 'none';
      }
      */
    }
  }

  pruneDropdowns() {
    let firstOpen = false;
    for (let dropdown of document.getElementsByClassName('navigation-menu')) {
      if (dropdown.open) {
        if (!firstOpen) {
          firstOpen = true;
          continue;
        }
        dropdown.open = false;
      }
    }
  }
  
  closeDropdowns(e) {
    if (window.getComputedStyle(document.getElementsByClassName('mobile-menu-toggler')[0]).display === 'block') {
      return;
    }
    if (document.getElementsByClassName('mobile-menu-toggler')[0].style.display === 'none') {
      return;
    }
    if (!e.target.open) {
      return;
    }
    for (let dropdown of document.getElementsByClassName('navigation-menu')) {
      if (dropdown !== e.target && dropdown.open) {
        dropdown.open = false;
      }
    }
  }
}

export default NavButton;
